fieldset {
	border: 0;
}

.ui-form-legend {
	background: none repeat scroll 0 0 #e8e8ce;
	color: #949459 !important;
}

.ui-form-fieldset.collapsed .ui-form-legend {
	background: none repeat scroll 0 0 #e8e8ce;
}

.ui-form-legend h3 {
	color: #949459 !important;
}

.ui-form-legend h3 .ui-form-legend-step {
	color: #949459 !important;
}

.ui-form-panel {
	border: 2px solid #e8e8ce;
}

.payment-steps-bar .payment-step.complete .payment-step-item {
	background-color: #949459 !important;
}

.payment-steps-bar .payment-step.complete .payment-step-item-number {
	color: #949459 !important;
}

.payment-steps-bar .payment-step.current .payment-step-item {
	background-color: #e8e8ce !important;
	color: #949459 !important;
}

.payment-steps-bar .payment-step.current .payment-step-item-number {
	color: #949459 !important;
}

.ui-form-label {
	color: #777777;
	font-size: 13px;
}

#session-message-wrapper.on-homepage #message {
	margin-bottom: 0px;
}
